import React from "react";

import { Router , Link } from "@reach/router";

import Post from './components/post';
import Posts from './components/posts';
import MakePost from './components/makepost'
import Register from './components/register'

import './App.css';

function App() {
    return (
	    <div className={'default'}>
		<h1>Rocky Mountains</h1>
		<Link to={"/"}>Home</Link>{"   "}
		<Link to={"/post"}>Make a Post</Link>{"   "}
		<Link to={"/register"}>Register</Link>
		<Router>
		    <Posts path="/" />
		    <Post path="/posts/:id" />
		    <MakePost path="/post" />
		    <Register path="/register" />
		</Router>
	    </div>
    );
}

export default App;
