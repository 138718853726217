import React, { useEffect, useState } from "react";
import { Link } from "@reach/router";

const Post = ({ id }) => {
    console.log(id);
    const [post, setPost] = useState({});

    useEffect(() => {
	const getPost = async () => {
	    const resp = await fetch(
		`https://general.roxannerepairs.workers.dev/api/posts/${id}`
	    );
	    console.log(resp);
	    const postResp = await resp.json();
	    setPost(postResp);
	};
 
	getPost();
    }, [id]);

    if (!Object.keys(post).length) return <div />;

    return (
	<div>
	    <h1>{post.title}</h1>
	    <h3>{post.username}</h3>
	    <p>{post.content}</p>
	    <p>
		<Link to="/">Go back</Link>
	    </p>
	</div>
    );
};

export default Post
