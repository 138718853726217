import React, { useEffect, useState } from "react";
import { Link } from "@reach/router"

const Posts = () => {
    const [posts, setPosts] = useState([]);

    useEffect(() => {
	const getPosts = async () => {
	    const resp = await fetch(
		"https://general.roxannerepairs.workers.dev/api/posts"
	    );
	    const postsResp = await resp.json();
	    setPosts(postsResp);
	};

	getPosts();
    }, []);

    return (
	<div>
	    <table style={{'textAlign': 'left', 'margin': '20px 10px 20px 10px'}}>
		{posts.map((post) => (
		    <div key={post.postid}>
			<tr>
			    <span>
				    <Link to={`/posts/${post.postid}`}>
					{post.title}
				    </Link>{` (by ${post.username})`}
			    </span>
			</tr>			    
		    </div>
	    ))}
	    </table>
	</div>
    );
}


export default Posts;
