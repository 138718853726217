import React, { useEffect, useState } from "react";

const MakePost = () => {

    let [uname,setUname] = useState("");
    let [password,setPassword] = useState("");        
    let [title,setTitle] = useState("");
    let [content,setContent] = useState("");
    let [report, setReport] = useState("");

    // when submit button is pressed, send a POST to backend
    const handleSubmit = (event) => {
	// V this is extrememly important! It prevents default sending
	// of data through URL
	event.preventDefault();
	
	setReport(<div>{"Sending post..."}</div>);
	
	const req_body = JSON.stringify({
	    username: uname, title:title,
	    content:content, password:password
	});

	const apiUrl = "https://general.roxannerepairs.workers.dev/api/posts";
	
	fetch(apiUrl, { method: 'POST',  body: req_body,
			headers: {'Content-Type': 'application/json'}
		      })
	    .then(resp => {
		if (resp.ok) {
		    setReport(<div>
				  {"Posted!"}
			      </div>);
		}
		else {
		    throw new Error(`Status ${resp.status}: ${resp.statusText}`);
		}
	    }
	    )
	    .catch(err => setReport(<div style={{'color':'red'}}>
					{err.name + ": " + err.message}
				    </div>));
    };

    const makeInputField = (fieldname, setfunc) => {
	return (
	    <div>
		<label>{fieldname}{" "}</label>
		<input type="text" onChange={(event) => setfunc(event.target.value)} />
	    </div>
	);
    }

    return (
	<div>
	    <form onSubmit={handleSubmit}>
		{makeInputField("Title", setTitle)}
		{makeInputField("Username", setUname)}
		{makeInputField("Password", setPassword)}		
		{makeInputField("Content", setContent)}
		<button type="submit">Submit</button>
	    </form>
	    <h3>{report}</h3>
	</div>
    );
};

export default MakePost;
