import React, { useEffect, useState } from "react";

const MakePost = () => {

    let [username,setUsername] = useState("");
    let [password,setPassword] = useState("");
    let [report, setReport] = useState("");

    // when submit button is pressed, send a POST to backend
    const handleSubmit = (event) => {
	// V this is extrememly important! It prevents default sending
	// of data through URL
	event.preventDefault();
	
	const req_body = JSON.stringify({
	    username: username, password: password
	});

	const apiUrl = "https://general.roxannerepairs.workers.dev/api/register";
	
	fetch(apiUrl, { method: 'POST',  body: req_body,
			headers: {'Content-Type': 'application/json'}
		      })
	    .then(resp => {
		if (resp.ok) {
		    setReport(<div>
				  {"Successfully registered!"}
			      </div>);
		}
		else {
		    throw new Error(`Status ${resp.status}: ${resp.statusText}`);
		}
	    }
	    )
	    .catch(err => setReport(<div style={{'color':'red'}}>
					{err.name + ": " + err.message}
				    </div>));
    };

    const makeInputField = (fieldname, setfunc) => {
	return (
	    <div>
		<label>{fieldname}{" "}</label>
		<input type="text" onChange={(event) => setfunc(event.target.value)} />
	    </div>
	);
    }

    return (
	<div>
	    <form onSubmit={handleSubmit}>
		{makeInputField("Username", setUsername)}
		{makeInputField("Password", setPassword)}
		<button type="submit">Submit</button>
	    </form>
	    <h3>{report}</h3>
	</div>
    );
};

export default MakePost;
